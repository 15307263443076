@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Finger+Paint&family=Grandstander:ital,wght@0,100..900;1,100..900&family=Lemon&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Bubblegum Sans", sans-serif;
}
html,
body {
  height: 100vh;
  width: 100vw;
  scrollbar-width: none;
}
body {
  background: url("./Assets/bg.png") no-repeat center fixed;
  background-size: cover;
  overflow-y: auto;
}
