.cont {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 15px;
  gap: 30px;
  flex-direction: column;
}
.cont > .card {
  max-width: 1200px;
  background: #0095c7c4;
  padding: 25px 20px;
  border-radius: 10px;
}
.cont > .card > .top {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-bottom: 20px;
}
.cont > .card > .top > img {
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.493);
  box-shadow: 8px 9px 21px -1px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}
.cont > .card > .top > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cont > .card > .top > div > .head {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.8px;
}
.cont > .card > .top > div > .desc {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: justify;
}
.cont > .card > .head {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.8px;
  margin: 20px 0 5px;
}
.cont > .card > .desc {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: justify;
  margin: 6px 0;
}
.cont > a {
  text-decoration: none;
  background: #0300c7a8;
  color: white;
  padding: 8px 14px;
  font-size: 18px;
  letter-spacing: 1.5px;
  border-radius: 8px;
  transition: all 0.75s;
}
.cont > a:hover {
  background: #0300c7;
  padding: 8px 70px;
  letter-spacing: 4px;
  border-radius: 20px;
}
@media screen and (max-width: 900px) {
  .cont > .card > .head,
  .cont > .card > .top > div > .head {
    font-size: 26px;
  }
  .cont > .card > .desc,
  .cont > .card > .top > div > .desc {
    font-size: 17px;
    letter-spacing: 0.3px;
  }
}
@media screen and (max-width: 750px) {
  .cont > .card > .top > img {
    width: 240px;
  }
}
@media screen and (max-width: 650px) {
  .cont > .card {
    padding: 15px 10px;
  }
  .cont > .card > .top {
    flex-direction: column;
  }
  .cont {
    padding: 15px 7.5px;
    gap: 20px;
  }
}
