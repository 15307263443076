.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  gap: 30px;
}
.home > .header {
  font-size: 32px;
  font-weight: 600;
  color: #000;
  text-align: center;
  padding: 0 15px;
  letter-spacing: 1px;
}
.home > .cards {
  max-width: 1400px;
  margin: 0 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}
.home > .cards > .card {
  width: 400px;
  background: #0095c78a;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 20px 0;
  text-decoration: none;
  box-shadow: 8px 9px 21px -1px rgba(0, 0, 0, 0.75);
  transition: all 0.7s;
}
.home > .cards > .card:hover {
  background: #0095c7;
  box-shadow: 0px 0px 25px 4px #000;
}
.home > .cards > .card > img {
  width: 400px;
}
.home > .cards > .card > .name {
  font-size: 22px;
  letter-spacing: 3px;
  color: #000;
  font-weight: 700;
  text-align: center;
}
.home > .cards > .card > .desc {
  font-size: 16px;
  letter-spacing: 1px;
  color: #000;
  font-weight: 400;
  text-align: center;
  padding: 0 6px;
}

@media screen and (max-width: 900px) {
  .home > .cards > .card {
    width: 260px;
  }
  .home > .cards > .card > img {
    width: 260px;
  }
  .home > .cards {
    gap: 30px;
  }
}
